<template>
  <div id="organizationManagement" class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">鉴定机构分成</a>
        </span>
      </div>

      <div class="framePage-body">
        <!-- 查询 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="结算状态" class="searchboxItem ci-full">
              <span class="itemLabel">结算状态:</span>
              <el-select
                v-model="settlement"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="所在区域" class="searchboxItem ci-full">
              <span class="itemLabel">所在区域:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >搜索</el-button
            >
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="鉴定机构名称"
                align="left"
                prop="compName"
                minWidth="200"
                show-overflow-tooltip
              />
              <el-table-column
                label="所在区域"
                align="left"
                prop="areaNamePath"
                minWidth="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="负责人"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.compUser || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="联系方式"
                align="left"
                prop="compPhone"
                show-overflow-tooltip
              />
              <el-table-column
                label="已完成鉴定考试"
                align="left"
                prop="completedExam"
                minWidth="100"
                show-overflow-tooltip
              />
              <el-table-column
                label="总鉴定人数"
                align="left"
                prop="totalPerson"
                show-overflow-tooltip
              />
              <el-table-column
                label="已结算人数"
                align="left"
                prop="settlementNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="待结算人数"
                align="left"
                prop="nonSettlementNum"
                show-overflow-tooltip
              />

              <el-table-column label="操作" align="center">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="ExportList(scope.row.compId)"
                    >导出名单</el-button
                  >
                </div>
              </el-table-column>
              <!-- <Empty slot="empty" /> -->
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      width="700px"
      top="2%"
      :before-close="handleClose"
    >
      <div class="ovy-a" style="height:600px">
        <component
          v-if="dialogShow"
          :is="componentName"
          :stu="stu"
          :id="id"
          @close="close"
        ></component>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";

export default {
  components: {
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      compId: "", //机构名称
      areaId: "", // 地区
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      settlement: "",
      options: [
        {
          value: true,
          label: "已结算",
        },
        {
          value: false,
          label: "未结算",
        },
      ],
      CompanyList:[],
    };
  },
  watch: {
    compId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  created() {
    this.getData();
    this.getareatree();
  },
  methods: {
    // 获取机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 关闭弹窗
    close() {
      this.dialogShow = false;
      this.getData();
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        settlement: this.settlement,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      this.doFetch({
        url: "/evaluate/share/page",
        params,
        pageNum,
      });
    },
    // 导出名单
    ExportList(compId) {
      // this.id = row.evaluateCompanyId;
      this.$router.push({
        path: "/web/InstitutionalExportList",
        query: {
          compId,
        },
      });
    },
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scoped>
#organizationManagement {
}
</style>
